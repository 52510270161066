import React from 'react'
import { withTranslation } from 'react-i18next'

function Indicator({ air_quality, status, t, title, unit, value }) {
  let valueFormatted = ''
  let valueClass = unit === 'hours' ? 'lowercase-text' : '';

  if (unit === 'hours') {
    let hours = Math.trunc(value)
    let minutes = Math.round((value % 1) * 60)
    if (value === 0) {
      valueFormatted = `${(hours > 0) ? `${hours}h` : ''}${(minutes >= 0) ? `${minutes}min` : ''}`;
    } else if (value >= 1) {
      valueFormatted = `>1h`;
    } else {
      valueFormatted = `${(hours > 0) ? `${hours}h` : ''}${(minutes > 0) ? `${minutes}min` : ''}`;
    }

  } else if (unit === 'percentage') {
    valueFormatted = `${Math.round(value * 100)}%`
  } else {
    valueFormatted = value
  }

  if ((status === 'closings' || status === 'quasistatic') && air_quality < 0.30) {
    return (
      <div className='building-zone-indicator status-indicator'>
        <h3>{t('common.ventilate_now')}</h3>
      </div>
    )
  }

  if(status === 'closings' && air_quality >= 0.3 && air_quality < 0.7) {
    return (
      <div className='building-zone-indicator'>
        <h3>
          {t(`indicators.time_to_next_ventilation`)}
        </h3>
        <span className={valueClass}>{valueFormatted}</span>
      </div>
    )
  }

  if(status === 'ventilations' && air_quality < 0.7) {
    return (
      <div>
        <div className='building-zone-indicator'>
          <h3>
            {t('indicators.required_ventilation_time')}
          </h3>
          <span className={valueClass}>{valueFormatted}</span>
        </div>
        <div className='building-zone-indicator status-indicator'>
          <h3>{t(`common.airing`)}</h3>
        </div>
      </div>
    )
  }

  if (status === 'quasistatic'&& air_quality >= 0.3 && air_quality < 0.7) {
    return (
      <div className='building-zone-indicator status-indicator'>
        <h3>{t('common.ventilation_recommended')}</h3>
      </div>
    )
  }

  if (air_quality >= 0.7) {
    return (
      <div className='building-zone-indicator status-indicator'>
        <h3>{t('common.good_job')}</h3>
      </div>
    )
  }

  return (
    <div className='building-zone-indicator'>
      <h3>
        {t(`indicators.${title}`)}
      </h3>
      <span className={valueClass}>{valueFormatted}</span>
    </div>
  )
}

export default withTranslation()(Indicator)
