import React from 'react';

function LinkWrapper({ className, children, link, style }) {
    if(link !== undefined) {
        return(
            <a className={className} href={link} target='_blank' rel="noopener noreferrer" style={style}>
                {children}
            </a>
        )
    } else {
        return children;
    }
}

export default LinkWrapper;
