import React, { useState } from 'react';
import moment from 'moment';
import { classList, returnTagColor } from '../Helpers/Functions';
import { withTranslation } from 'react-i18next';

function Notification({ notification, archive, snooze, id, t, checked, handleClick }) {
    const [ isOpen, setIsOpen ] = useState(false);

    return(
        (notification !== undefined) ? (
            (notification.notifications.length > 1) ? (
                <div className="notification-box">
                    <div>
                        <input className="notification-checkmark"
                            id={notification.notifications.map(x => x.id)}
                            onChange={handleClick}
                            checked={checked}
                            type="checkbox"
                            disabled={(!notification.notifications[0].active && notification.notifications[0].archived)}/>
                    </div>
                    <div className={classList({
                                            "notification": true,
                                            "notification-multiple": true
                                        })}>
                        {
                            (true) ? (
                                <div>
                                    <div
                                        className={classList({
                                            "notification-dot": true,
                                            "new": 2
                                        })}
                                        style={{ 'backgroundColor': returnTagColor(notification.notifications[0].category) }}
                                    ></div>
                                    <div className="notification-details">
                                        <div>
                                            <div className="notification-details-text" onClick={() => setIsOpen(!isOpen)}>
                                                <span className="notification-details-zone">{notification.notifications[0].building_name} • {notification.notifications[0].zone_name}</span>
                                                <span>•</span>
                                                <span className="notification-details-date">{moment(notification.notifications[0].date).format('DD MMMM YYYY HH:mm')}h</span>
                                            </div>
                                            {
                                                (isOpen) ? (
                                                    notification.notifications.filter(notification_ => (notification_.id !== notification.notifications[0].id)).map(notification => (
                                                        <div>
                                                            <span className="notification-details-zone">{notification.building_name} • {notification.zone_name}</span>
                                                            <span>•</span>
                                                            <span className="notification-details-date">{moment(notification.date).format('DD MMMM YYYY HH:mm')}h</span>
                                                        </div>
                                                    ))
                                                ) : false
                                            }
                                        </div>
                                        <div className={classList({
                                            "notification-icons": true,
                                            "notification-title": true,
                                            "archived": notification.notifications[0].archived,
                                        })}>
                                            {
                                                (notification.notifications[0].active && !notification.notifications[0].archived) ? (
                                                    <i className="snooze-icon" onClick={() => snooze(notification.notifications.map(x => x.id), notification.type)}/>
                                                ) : false
                                            }
                                            {
                                                (notification.notifications[0].active) ? (
                                                    <i className="archive-icon" onClick={() => archive(notification.notifications.map(x => x.id), notification.notifications[0].archived, "archived")}/>
                                                ) : false
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : false
                        }
                        <div className={classList({
                            "notification-title": true,
                            "archived": true,
                        })}>
                            <h2>{notification.notifications[0].alert}</h2>
                        </div>
                        {
                            (notification.notifications[0].benefit !== null) ? (
                                <div className="notification-body">
                                    <div className="notification-body_icon">
                                        <div className="ico ico--thumbs-up"/>
                                    </div>
                                    <div className="notification-body-text">{notification.notifications[0].benefit}</div>
                                </div>
                            ) : false
                        }
                    </div>
                </div>

            ) : (
                <div className="notification-box">
                    <div>
                        <input className="notification-checkmark"
                            id={notification.notifications[0].id}
                            onChange={handleClick}
                            checked={checked}
                            type="checkbox"
                            disabled={(!notification.notifications[0].active && notification.notifications[0].archived)}/>
                    </div>
                    <div className={classList({
                                            "notification": true,
                                            "notification-multiple": false
                                        })}>
                        {
                            (true) ? (
                                <div>
                                    <div
                                        className={classList({
                                            "notification-dot": true,
                                            "new": 2
                                        })}
                                        style={{ 'backgroundColor': returnTagColor(notification.notifications[0].category) }}
                                    ></div>
                                    <div className="notification-details">
                                        <div>
                                            <span className="notification-details-zone">{notification.notifications[0].building_name} • {notification.notifications[0].zone_name}</span>
                                            <span>•</span>
                                            <span className="notification-details-date">{moment(notification.notifications[0].date).format('DD MMMM YYYY HH:mm')}h</span>
                                        </div>
                                        <div className={classList({
                                            "notification-icons": true,
                                            "notification-title": true,
                                            "archived": notification.notifications[0].archived,
                                        })}>
                                            {
                                                (notification.notifications[0].active && !notification.notifications[0].archived) ? (
                                                    <i className="snooze-icon" onClick={() => snooze(notification.notifications[0].id, notification.notifications[0].type)}/>
                                                ) : false
                                            }
                                            {
                                                (notification.notifications[0].active) ? (
                                                    <i className="archive-icon" onClick={() => archive(notification.notifications[0].id, notification.notifications[0].archived, "archived")}/>
                                                ) : false
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : false
                        }
                        <div className={classList({
                            "notification-title": true,
                            "archived": true,
                        })}>
                            <h2>{notification.notifications[0].alert}</h2>
                        </div>
                        {
                            (notification.notifications[0].benefit !== null) ? (
                                <div className="notification-body">
                                    <div className="notification-body_icon">
                                        <div className="ico ico--thumbs-up"/>
                                    </div>
                                    <div className="notification-body-text">{notification.notifications[0].benefit}</div>
                                </div>
                            ) : false
                        }
                    </div>
                </div>
            )

            ) :
            (
                <div className="notification-box">
                    <div className={classList({
                                            "notification": true,
                                            "notification-multiple": true,
                                            "no-notifications": true
                                        })}>
                        <div className={classList({
                            "notification-title": true
                        })}>
                            {t('notifications.none')}
                        </div>
                        <div className="squeleton-line" style={{ minWidth: '80px', width: '15%' }} />
                    </div>
                </div>
            )
    )
}

export default withTranslation()(Notification);
