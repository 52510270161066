import React, { useEffect, useState } from 'react';
import { classList } from '../Helpers/Functions';
import { withTranslation } from 'react-i18next';

function PreferencesTypes({ t, setChannels, channels }) {

    const [categoriesUnique, setCategoriesUnique] = useState({});

    useEffect(() => {
        setCategoriesUnique(channels.filter((elem, index) => channels.findIndex(obj => obj.category === elem.category) === index))
    }, [channels])

    function updateCheckbox(cat, ind, currVal) {
        let temp = [...channels]

        temp.forEach(elem => {
            if (elem.category === cat) {
                elem.channels[ind].checked = !currVal
            }
        })
        setChannels(temp)
    }

    return (
        <div className='card p-3 mb-sm-3 mb-md-0'>
            <label htmlFor='freeDays'>
                <h5 className='chart-title'>{t('climate.channels')}</h5>
            </label>
            <div className="preferences-card box">
                <div>&nbsp;</div>
                <div className="preferences-list-checkbox">
                    <div className="ico ico--power-off-solid preferences-checkbox-active" />
                    <div className="ico ico--webpush_browser" />
                    <div className="ico ico--whatsapp" />
                    <div className="ico ico--envelope-regular" />
                </div>
            </div>
            {
                (categoriesUnique !== undefined && categoriesUnique.length > 0) ? (
                    categoriesUnique.map(type => (
                        <div className="preferences-card">
                            <div className="preferences-text px-1">{t(`tags.${type.category}`)}</div>
                            <div className="preferences-list-checkbox">
                                {
                                    type.channels.map((channel, inx) => (
                                        <input className={classList({
                                            "preferences-checkbox": true,
                                            "preferences-checkbox-active": (channel.label === 'active')
                                        })} disabled={!type.channels[0].checked && channel.label !== 'active'} checked={channel.checked} onChange={() => {
                                            updateCheckbox(type.category, inx, channel.checked)
                                        }} type="checkbox"></input>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                ) : false
            }
        </div>
    )
}
export default withTranslation()(PreferencesTypes);
