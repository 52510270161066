import React from 'react';
import { withTranslation } from 'react-i18next'

import lightOn from '../Img/icons/light_on.svg';
import lightOff from '../Img/icons/light_off.svg';
import thermostatOn from '../Img/icons/thermostat_on.svg';
import thermostatOff from '../Img/icons/thermostat_off.svg';

function SettingsSidebar({ items, unblockThermostat, setZoneSettingsData, t }) {
    if(items.length > 0) {
        items = items.sort((a, b) => (a.prop > b.prop) ? 1 : -1);
        return(
            <div className='machinery-dashboard-kpis device-settings'>
                <span className="device-settings-head">{t('common.device_config')}</span>
                {
                    items.map((item, index) => {
                        if (item.prop === 'co2_light_warning') {
                            return (
                                <div className="dashboard-kpi device-setting" key={index}>
                                    <div className='g-row'>
                                        {
                                            item.value ? (<img src={lightOn} className="ico" alt="Light on" />) : (<img src={lightOff} className="ico" alt="Light off" />)
                                        }
                                    </div>
                                    <div className='g-row'>
                                        <label className='switch' title=''>
                                            <input
                                                name='switch'
                                                type='checkbox'
                                                value='1'
                                                onChange={() => setZoneSettingsData((prevState) => { return { ...prevState, co2_light_warning: !item.value } })}
                                                checked={item.value}
                                            />
                                            <span className='slider round'/>
                                        </label>
                                    </div>
                                    <div className='g-row'>
                                        <div className="device-setting-name">{t(`common.${item.prop}`)}</div>
                                    </div>
                                </div>
                            )
                        } else if (item.prop === 'thermostat_status' && item.value !== undefined) {
                            return (
                                <div className="dashboard-kpi device-setting" key={index}>
                                    <div className='g-row'>
                                        {
                                            item.value ? (<img src={thermostatOn} className="ico" alt="Thermostat on" />) : (<img src={thermostatOff} className="ico" alt="Thermostat off" />)
                                        }
                                    </div>
                                    <div className='g-row'>
                                        <label className='switch' title=''>
                                            <input
                                                name='thermostat_status'
                                                type='checkbox'
                                                value='1'
                                                onChange={() => unblockThermostat(!item.value)}
                                                checked={item.value}
                                            />
                                            <span className='slider round'/>
                                        </label>
                                    </div>
                                    <div className='g-row'>
                                        <div className="device-setting-name">{(item.value) ? t(`common.${item.prop}.on`) : t(`common.${item.prop}.off`)}</div>
                                    </div>
                                </div>
                            )
                        } else {
                            return false;
                        }
                    })
                }
            </div>
        )
    } else {
        return false;
    }
}

export default withTranslation()(SettingsSidebar);
