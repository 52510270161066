import React, { useContext, useEffect, useState } from 'react';
import Loading from '../Components/Loading'
import UtilsContext from '../Context/utilsContext';

function Zones({ location }) {
    const splitedPath = location.pathname.split('/');
    const [ zonesData, setZonesData ] = useState({ building: parseInt(splitedPath[splitedPath.length - 1]), zones: undefined });
    const { customers } = useContext(UtilsContext)

    useEffect(() => {
        if(customers.length !== 0) {
            let selectedBuilding = null;
            customers.buildings.forEach(customerBuilding => (customerBuilding.id === parseInt(zonesData.building) ? selectedBuilding = customerBuilding : false));
            setZonesData({ ...zonesData, zones: selectedBuilding.zones });
        }
    }, [customers, zonesData]);

    var content;

    if (zonesData.zones === undefined) {
        content = (
            <Loading></Loading>
        );
    } else {
        content = (
            <main role='main' className='col-12 main-dashboard'></main>
        );
    }

    return content;
};

export default Zones;
