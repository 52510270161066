import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import UtilsContext from '../Context/utilsContext';

function Modules({ location, t }) {
    const zone = location.pathname.replace('/modules/', '');
    const [modulesData, setModulesData] = useState(undefined);
    const { customers } = useContext(UtilsContext);

    useEffect(() => {
        if(customers.length !== 0) {
            let selectedZone = null;
            customers.buildings.forEach(customerBuilding => customerBuilding.zones.forEach(buildingZone => (buildingZone.id === parseInt(zone) ? selectedZone = buildingZone : false)));
            setModulesData(selectedZone);
        }
    }, [customers, zone]);

    var content;

    if (modulesData === undefined) {
        content = (
            <main role='main' className='col-12'>
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom'>
                    <h2>
                        <Spinner animation='border' />
                        {t('common.loading')}
                    </h2>
                </div>
            </main>
        );
    } else {
        content = (
            <main role='main' className='col-12'></main>
        );
    }

    return content;
}

export default withTranslation()(Modules);
