export const INITIAL_STATE = (t) => {
    return {
        buildings: [],
        zoneId: Number(parseInt(window.location.pathname.split('/')[2])),
        zones: [],
        loading: true,
        settings: {
            excluded_dates: []
        },
        target: null,
        enableSaveSettings: false,
        savingSettings: false,
        enableExcludedDaysSettings: false,
        originalSettings: undefined,
        progressPercent1: 100,
        progressPercent2: 0,
        progressPercent3: 0,
        sameBuildingZones: {},
        days: {
            monday: {
                name: t('weekdays.monday'),
                enabled: false,
                start: null,
                end: null
            },
            tuesday: {
                name: t('weekdays.tuesday'),
                enabled: true,
                start: null,
                end: null
            },
            wednesday: {
                name: t('weekdays.wednesday'),
                enabled: true,
                start: null,
                end: null
            },
            thursday: {
                name: t('weekdays.thursday'),
                enabled: true,
                start: null,
                end: null
            },
            friday: {
                name: t('weekdays.friday'),
                enabled: true,
                start: null,
                end: null
            },
            saturday: {
                name: t('weekdays.saturday'),
                enabled: true,
                start: null,
                end: null
            },
            sunday: {
                name: t('weekdays.sunday'),
                enabled: true,
                start: null,
                end: null
            }
        },
        co2_threshold: 1000,
        co2_light_warning: false
    };
};

export const PREPARED_SETTINGS = (zoneSettings) => {
    return {
            co2_light_warning: zoneSettings.co2_light_warning,
            co2_threshold: parseInt(zoneSettings.co2_threshold),
            excluded_dates: zoneSettings.settings.excluded_dates,
            monday_enabled: zoneSettings.days.monday.enabled,
            monday_start: zoneSettings.days.monday.start,
            monday_end:zoneSettings.days.monday.end,
            tuesday_enabled: zoneSettings.days.tuesday.enabled,
            tuesday_start: zoneSettings.days.tuesday.start,
            tuesday_end: zoneSettings.days.tuesday.end,
            wednesday_enabled: zoneSettings.days.wednesday.enabled,
            wednesday_start: zoneSettings.days.wednesday.start,
            wednesday_end: zoneSettings.days.wednesday.end,
            thursday_enabled: zoneSettings.days.thursday.enabled,
            thursday_start: zoneSettings.days.thursday.start,
            thursday_end: zoneSettings.days.thursday.end,
            friday_enabled: zoneSettings.days.friday.enabled,
            friday_start: zoneSettings.days.friday.start,
            friday_end: zoneSettings.days.friday.end,
            saturday_enabled: zoneSettings.days.saturday.enabled,
            saturday_start: zoneSettings.days.saturday.start,
            saturday_end: zoneSettings.days.saturday.end,
            sunday_enabled: zoneSettings.days.sunday.enabled,
            sunday_start: zoneSettings.days.sunday.start,
            sunday_end: zoneSettings.days.sunday.end,
            target: parseInt(zoneSettings.target),
    };
};
