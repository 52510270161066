import React from 'react'
import { Trans, withTranslation } from 'react-i18next'

function DashboardKpi({ kpis, power, t }) {
  let relay_is_on = '';
  let relay_date = '';
  let occupied_kpi = '';
  let occupancy_kpi = '';

  if (power) {
    return (
      <div className='machinery-dashboard-kpis'>
      <div className='dashboard-kpi'>
      <div className='ico ico--lightning_bolt' />
      <div className="kpi-title">{t('common.max_w')}</div>
      {kpis.max !== null ? (<span>{kpis.max} kW</span>) : <span>{t('common.null_energy')}</span>}
      </div>

      <div className='dashboard-kpi'>
      <div className='ico ico--plugging_electricity' />
      <div className="kpi-title">{t('common.total_wh')}</div>
      {kpis.total_active_energy !== null ? (<span>{kpis.total_active_energy} kWh</span>) : <span>{t('common.null_energy')}</span>}
      </div>

      <div className='dashboard-kpi'>
      <div className='ico ico--money' />
      <div className="kpi-title">{t('common.price')}</div>
      {kpis.price !== null ? (<span>{kpis.price} €</span>) : <span>{t('common.null_energy')}</span>}
      </div>
      </div>
      )
    }

  if (kpis.get_activity_relay === 'Heat') {
    relay_is_on = (
      <div>
        <div className='ico ico--warm' />
        <div className="kpi-title">{t('common.heating')}</div>
      </div>
    )
  } else if (kpis.get_activity_relay === 'Cool') {
    relay_is_on = (
      <div>
        <div className='ico ico--cool' />
        <div className="kpi-title">{t('common.cooling')}</div>
      </div>
    )
  } else {
    relay_is_on = (
      <div>
        <div className='ico ico--gray' />
        <div className="kpi-title">{t('common.off')}</div>
      </div>
    )
  }

  if(kpis.last_on_relay_date === '--') {
    relay_date = (
      <div className='dashboard-kpi'>{
        <div className='dashboard-kpi'>
          <div className='ico ico--clock' />
          <div className="kpi-title">{t('common.start_time')}</div>
          <span>
            {t('common.without_activity')}
          </span>
        </div>
      }</div>
    )
  } else {
    relay_date = (
      <div className='dashboard-kpi'>{
        <div className='dashboard-kpi'>
          <div className='ico ico--clock' />
          <div className="kpi-title">{t('common.start_time')}</div>
          <span>
            {`${kpis.last_on_relay_date} h`}
          </span>
        </div>
      }</div>
    )
  }

  if(kpis.occupancy !== undefined && kpis.occupancy.occupancy !== null) {
    if (kpis.occupancy.capacity !== null) {
      const occupancyPercentage = kpis.occupancy.occupancy / kpis.occupancy.capacity;

      let iconClass = 'ico ico--person-fill';
      if (occupancyPercentage >= kpis.occupancy.high_thr) {
        iconClass = 'ico ico--group-people-fill';
      } else if (occupancyPercentage >= kpis.occupancy.low_thr) {
        iconClass = 'ico ico--two-person-fill';
      } else if (occupancyPercentage === 0) {
        iconClass = 'ico ico--person-empty';
      }

      occupancy_kpi = (
        <div className='dashboard-kpi'>
          <div className={iconClass} />
          <div className="kpi-title">{t('common.occupancy')}</div>
          <span>{kpis.occupancy.occupancy}</span>
        </div>
      )
    } else {
      occupancy_kpi = (
        <div className='dashboard-kpi'>
          <div
            className={`${
              kpis.occupancy.occupancy === 0
                ? 'ico ico--person-empty'
                : 'ico ico--person-fill'
            }`}
          />
          <div className="kpi-title">{t('common.occupancy')}</div>
          <span>{kpis.occupancy.occupancy}</span>
        </div>
      )
    }
  } else if(kpis.occupancy !== undefined && kpis.occupancy.movement !== null) {
    occupancy_kpi = (
      <div className='dashboard-kpi'>
        <div
          className={`${
            kpis.occupancy.movement === 0
              ? 'ico ico--person-empty'
              : 'ico ico--person-fill'
          }`}
        />
        <div className="kpi-title">{t('common.movement')}</div>
        <span>{kpis.occupancy.movement === 0
              ? t('common.movement_free')
              : t('common.movement_busy')}</span>
      </div>
    )
  }

  if(kpis.occupied) {
    occupied_kpi = (
      <div className='dashboard-kpi occupied-kpi'>{
        <div className='dashboard-kpi'>
          <div className='occupied-circle' />
          <div>{t('common.occupied')}</div>
        </div>
      }</div>
    )
  } else if(kpis.occupied !== undefined) {
    occupied_kpi = (
      <div className='dashboard-kpi occupied-kpi not-occupied'>{
        <div className='dashboard-kpi'>
          <div className='occupied-circle' />
          <div>{t('common.not_occupied')}</div>
        </div>
      }</div>
    )
  }

  return (
    <div className='machinery-dashboard-kpis'>
      {kpis.occupied !== undefined && (occupied_kpi)}
      {kpis.get_activity_relay !== undefined ? (<div className='dashboard-kpi'>{relay_is_on}</div>) : false}
      {kpis.last_on_relay_date !== undefined && (relay_date)}
      {kpis.Temperature !== undefined && (
        <div className='dashboard-kpi'>
          <div className='ico ico--thermometer' />
          <div className="kpi-title">{t('common.current')}</div>
          <span>{kpis.Temperature}ºC</span>
        </div>
      )}

      {kpis.Humidity !== undefined && (
        <div className='dashboard-kpi'>
          <div className='ico ico--drop' />
          <div className="kpi-title">H {t('common.interior')}</div>
          <span>{kpis.Humidity}%</span>
        </div>
      )}
      {(kpis.ppm !== undefined) &&
      (
        <div className='dashboard-kpi'>
          <div
            className={`${
              kpis.ppm.status === 'ventilations'
                ? 'ico ico--ventilation--on'
                : 'ico ico--ventilation--off'
            }`}
          />
          <div className="kpi-title"><Trans components={[<sub />]} i18nKey='common.ppm' /></div>
          <span>{kpis.ppm.value}ppm</span>
        </div>
      )}
      {kpis.occupancy !== undefined && (kpis.occupancy.occupancy !== null || kpis.occupancy.movement !== null) ? (
        <div className='dashboard-kpi'>{occupancy_kpi}</div>
      ) : false}
    </div>
  )
}

export default withTranslation()(DashboardKpi)
