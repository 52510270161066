import React, { useEffect, useState } from 'react';
import { classList } from '../Helpers/Functions';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import DropdownTree from './DropdownTree';

function TagsFilter({ notificationsFilter, setNotificationsFilter, updateFilters, buildings, t, categories, setCurrentBuildingsChecked, currentBuildingsChecked }) {
  const [ currentBuildings, setCurrentBuildings ] = useState([])

  useEffect(() => {
    let temp = []
    let isIn = false
    buildings.forEach(building => {
      let temp_zones = []
      building.zones.forEach(zone => {
        temp_zones.push({value: zone.name, id: zone.id})
      })
      currentBuildings.forEach(build => {
        if (build.value === building.name && build.tree) {
          temp.push({value: build.value, id: build.id, tree: build.tree, zones: build.zones})
          isIn = true
        }
      })
      if (!isIn) {
        temp.push({value: building.name, id: building.id, tree: false, zones: temp_zones})
      }
      isIn = false
    })
    setCurrentBuildings(temp)
  }, [buildings])

    return (
        <div className="notifications-filter">
          <div className="notifications-filter-tags">
            <Select
              onChange={updateFilters}
              options={categories}
              isMulti={true}
              closeMenuOnSelect={false}
              className="dropdown-multiselect"
              placeholder={t(`notifications.category_button`)}
              closeMenuOnScroll={false}
              noOptionsMessage={() => t(`notifications.no_options`)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: 'transparent',
                  primary50: 'transparent'
                },
              })}
            />
            <DropdownTree
              is_filter={true}
              currentBuildings={currentBuildings}
              currentBuildingsChecked={currentBuildingsChecked}
              setCurrentBuildingsChecked={setCurrentBuildingsChecked}
              setCurrentBuildings={setCurrentBuildings}
            />
            </div>
            <div className="notification-filter-action">
                    <div className="filter-blur"></div>
                    <div
                        className={classList({
                        'notification-button': true,
                        'selected': notificationsFilter.archived
                        })}
                        onClick={() => (setNotificationsFilter(prevState => ({
                            ...prevState,
                            archived: !notificationsFilter.archived
                          }))
                      )}
                    >
                        <i className="archive-icon" />
                    </div>
                </div>
        </div>
    )
}

export default withTranslation()(TagsFilter);
