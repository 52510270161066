import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import { handleWebserviceError } from '../Helpers/Functions';
import { withTranslation } from 'react-i18next';
import { GET_CHART_ENERGY } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';

window.tempInterval = null;
function ChartPower({ date, moduleId, t }) {
    const [chartData, setChartData] = useState({ data: {}, moduleId });
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;

    useEffect(() => {
        window.clearInterval(window.tempInterval);
        if(moduleId !== undefined) {
            callAPI(GET_CHART_ENERGY(moduleId, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            window.tempInterval = window.setInterval(() => {
                callAPI(GET_CHART_ENERGY(moduleId, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            }, 300000);
        } else {
            setChartData({ data: {}, moduleId });
        }
    }, [moduleId, date]);

    useEffect(() => {
        setChartData({ data: {}, moduleId });
    }, [moduleId]);

    useEffect(() => {
        if(data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined && type === 'GET_CHART_ENERGY') {
            setChartData({ ...chartData, data: data });
        }
    }, [data]);

    if (data && data.date.length > 0) {
        var chartTemperatura = {
            chart: {
                type: 'area'
            },
            xAxis: {
                categories: data.date
            },
            title: false,
            yAxis: {
                title: {
                    text: `${t('common.energy')} (kWh)`
                },
                min: 0
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            series: [
                {
                    name: t('common.total_wh'),
                    color: '#00FFCC',
                    data: data.active_energy,
                    type: 'areaspline',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, 'rgb(179,255,241)'],
                            [1, 'rgba(216,255,247,0)']
                        ]
                    },
                    lineWidth: 4,
                    states: {
                        hover: {
                            lineWidth: 3,
                            marker: {
                                radius: 4,
                            }
                        }
                    },
                    threshold: null,
                    marker: {
                        enabled: false
                    }
                }
            ]
        };

        if(data !== undefined) {
            return (
                <div>
                    <div className='chart-container_power'>
                        <h6 className='chart-title pt-3 ml-3'>{t('climate.power_consumption')}</h6>
                        <HighchartsReact
                            containerProps={{ style: { height: "100%" } }}
                            options={chartTemperatura}
                        />
                    </div>
                </div>
            )
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export default withTranslation()(ChartPower);
