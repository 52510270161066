const DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

export const LOGIN = {
    method: 'POST',
    path: '/api/login/',
    requiresAuth: false,
    type: 'LOGIN',
    headers: DEFAULT_HEADERS,
};

export const GET_CUSTOMERS = (customerId) => {
    return {
        method: 'GET',
        path: `/api/customers/${customerId}/`,
        requiresAuth: true,
        type: 'GET_CUSTOMERS',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_CHART_TEMPERATURE = (zoneId, date) => {
    return {
        method: 'GET',
        path: `/api/chart/temperature/${zoneId}/?date=${date}`,
        requiresAuth: true,
        type: 'GET_CHART_TEMPERATURE',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_CHART_HUMIDITY = (zoneId, date) => {
    return {
        method: 'GET',
        path: `/api/chart/humidity/${zoneId}/?date=${date}`,
        requiresAuth: true,
        type: 'GET_CHART_HUMIDITY',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_CHART_LIGHTING = (zoneId, date) => {
    return {
        method: 'GET',
        path: `/api/chart/lighting/${zoneId}/?date=${date}`,
        requiresAuth: true,
        type: 'GET_CHART_LIGHTING',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_CHART_OCCUPATION = (zoneId, date) => {
    return {
        method: 'GET',
        path: `/api/chart/occupation/${zoneId}/?date=${date}`,
        requiresAuth: true,
        type: 'GET_CHART_OCCUPATION',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_CHART_ENERGY = (buildingId, date) => {
    return {
        method: 'GET',
        path: `/api/chart/energy/${buildingId}/?date=${date}`,
        requiresAuth: true,
        type: 'GET_CHART_ENERGY',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_CO2_CHART = (zoneId, date) => {
    return {
        method: 'GET',
        path: `/api/chart/airquality/${zoneId}/?date=${date}`,
        requiresAuth: true,
        type: 'GET_CO2_CHART',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_SOUND_CHART = (zoneId, date) => {
    return {
        method: 'GET',
        path: `/api/chart/sound/${zoneId}/?date=${date}`,
        requiresAuth: true,
        type: 'GET_SOUND_CHART',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_ZONE = (zoneId) => {
    return {
        method: 'GET',
        path: `/api/zones/${zoneId}/`,
        requiresAuth: true,
        type: 'GET_ZONE',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_ZONE_KPIS = (zoneId, date) => {
    return {
        method: 'GET',
        path: `/api/kpis/${zoneId}/?date=${date}`,
        requiresAuth: true,
        type: 'GET_ZONE_KPIS',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_ZONE_SETTINGS = (settings, zoneId) => {
    return {
        method: 'GET',
        path: `/api/zonesettings/${settings}/?zone=${zoneId}`,
        requiresAuth: true,
        type: 'GET_ZONE_SETTINGS',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const CHANGE_ZONE_SETTINGS = (settings, zoneId) => {
    return {
        method: 'PUT',
        path: `/api/zonesettings/${settings}/?zone=${zoneId}`,
        requiresAuth: true,
        type: 'CHANGE_ZONE_SETTINGS',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_CO2_KPIS = (zoneId) => {
    return {
        method: 'GET',
        path: `/api/co2kpis/${zoneId}/`,
        requiresAuth: true,
        type: 'GET_CO2_KPIS',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const GET_ALERTS = (params = {}, page_num, page_range) => {
    // Construct the query string from the params object
    const queryString = Object.keys(params).map(key => {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(params[key]);
        return `${encodedKey}=${encodedValue}`;
    }).join('&');

    return {
        method: 'GET',
        path: `/api/v1/alerts/?${queryString}&page_num=${page_num+1}&page_range=${page_range}`,
        requiresAuth: true,
        type: 'GET_ALERTS',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const ALERTS_OVERVIEW = {
    method: 'GET',
    path: `/api/alerts/overview/`,
    requiresAuth: true,
    type: 'ALERTS_OVERVIEW',
    headers: {
        ...DEFAULT_HEADERS,
        'Cache-Control': 'no-cache'
    }
};

export const ALERT_TRACKER = {

    method: 'PATCH',
    path: `/api/v1/alerttrackers/`,
    requiresAuth: true,
    type: 'ALERT_TRACKER',
    headers: {
        ...DEFAULT_HEADERS,
        'Cache-Control': 'no-cache'
    }

};

export const SNOOZE_ALERT = {
    method: 'PATCH',
    path: `/api/v1/snooze/`,
    requiresAuth: true,
    type: 'SNOOZE_ALERT',
    headers: {
        ...DEFAULT_HEADERS,
        'Cache-Control': 'no-cache'
    }
};

export const GET_ALERTS_SETTINGS = (zoneId) => {
    return {
        method: 'GET',
        path: `/api/v1/alertsettings/?zone=${zoneId}`,
        requiresAuth: true,
        type: 'GET_ALERTS_SETTINGS',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const PATCH_ALERTS_SETTINGS = (alertsettingId) => {
    return {
        method: 'PATCH',
        path: `/api/v1/alertsettings/${alertsettingId}/`,
        requiresAuth: true,
        type: 'PATCH_ALERTS_SETTINGS',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const UNBLOCK_THERMOSTAT_STATUS = (zoneId) => {
    return {
        method: 'GET',
        path: `/api/maintenance/unblockthermostat/${zoneId}/`,
        requiresAuth: true,
        type: 'UNBLOCK_THERMOSTAT_STATUS',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const UNBLOCK_THERMOSTAT = (zoneId) => {
    return {
        method: 'PUT',
        path: `/api/maintenance/unblockthermostat/${zoneId}/`,
        requiresAuth: true,
        type: 'UNBLOCK_THERMOSTAT',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const PUBLIC_GET_SCORES_KPIS = {
    method: 'GET',
    path: '/api/kpis/public/',
    requiresAuth: false,
    type: 'PUBLIC_GET_SCORES_KPIS',
    headers: DEFAULT_HEADERS,
};

export const PUBLIC_GET_BUILDING_ZONES = (buildingId) => {
    return {
        method: 'GET',
        path: `/api/kpis/public/old/building/${buildingId}/`,
        requiresAuth: false,
        type: 'PUBLIC_GET_BUILDING_ZONES',
        headers: DEFAULT_HEADERS
    }
};

export const WEBPUSH_SAVE_INFORMATION = {
    method: 'POST',
    path: `/webpush/save_information/`,
    requiresAuth: true,
    type: 'WEBPUSH_SAVE_INFORMATION',
    headers: {
        ...DEFAULT_HEADERS,
        'Cache-Control': 'no-cache'
    },
    credentials: 'include'
};

export const CHANGE_BUILDING_ZONE_NAME = (id) => {
    return {
        method: 'PUT',
        path: `/api/change_name/${id}/`,
        requiresAuth: true,
        type: 'CHANGE_BUILDING_ZONE_NAME',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const DOWNLOAD_FILE = (type) => {
    return {
        method: 'PUT',
        path: `/api/download/${type}/`,
        requiresAuth: true,
        type: 'DOWNLOAD_FILE',
        headers: {
            ...DEFAULT_HEADERS,
            'Cache-Control': 'no-cache'
        }
    }
};

export const UPDATE_SUBSCRIPTION_DATE = {
    method: 'PUT',
    path: `/update_subscription/`,
    requiresAuth: true,
    type: 'UPDATE_SUBSCRIPTION_DATE',
    headers: {
        ...DEFAULT_HEADERS,
        'Cache-Control': 'no-cache'
    }
};
