import React, { useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import UtilsContext from '../Context/utilsContext';
import Warning from '../Img/icons/warning_icon.svg';

function AlertsPopup({ t, setHasActiveNotifications }) {
    const { alerts } = useContext(UtilsContext);

    useEffect(() => {
        if (alerts.total_alerts > 0) {
            setHasActiveNotifications(true);
        }
    }, [alerts]);

    function hide_notifications_popup() {
        document.getElementById("container-alert").style.display = "none";
        document.getElementById("container-alert").style.visibility = "hidden";
    }

    return (
        (alerts.total_alerts > 0) ? (
            <div id="container-alert">
                <div id="exampleModal" className="reveal-modal">
                    <img className="alert-svg" src={Warning} alt='warning notification'/>
                        {
                            (alerts.total_alerts === 1 && alerts.buildings.length === 1) ? (
                                <div className="alert-box">
                                    {t('welcome.notification_singular', { alerts_count: alerts.total_alerts, alerts_building_count: alerts.buildings.length })}
                                </div>
                            ) : (alerts.total_alerts > 1 && alerts.buildings.length === 1) ? (
                                <div className="alert-box">
                                    {t('welcome.notification_singularPlural', { alerts_count: alerts.total_alerts, alerts_building_count: alerts.buildings.length })}
                                </div>
                            ) : (
                                <div className="alert-box">
                                    {t('welcome.notification_plural', { alerts_count: alerts.total_alerts, alerts_building_count: alerts.buildings.length })}
                                </div>
                            )
                        }
                    <div onClick={() => hide_notifications_popup()} className="close-reveal-modal">×</div>
                </div>
            </div>
        ) : false
    )
};

export default withTranslation()(AlertsPopup);
