import React from 'react';
import '../Styles/loading.css'
import { withTranslation } from 'react-i18next';

function Loading({ t }) {
    return (
        <main role='main' className='col-12 loading-main'>
            <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom loading-box'>
                <div className='loading justify-content-center align-items-center col-12'>
                    <div className='loader-container'><div className='loader'></div></div>
                    <div>{t('common.loading')}</div>
                </div>
            </div>
        </main>
    )
};

export default withTranslation()(Loading);
