import React from 'react';
import 'moment/locale/ca';
import 'moment/locale/es';
import { classList } from '../Helpers/Functions';

function DateFilter({ filter, dates, selected, setSelected }) {
    function renderYearMonths(year, yearMonths) {
        return yearMonths.map((month, index) => (
            <div
                className={classList({
                    'month-element': true,
                    'selected': (selected !== false) ? selected === `${month}-${year}` : false
                })}
                key={month}
                onClick={() => (
                    (selected !== month) ? (
                        filter(year, month),
                        setSelected(`${month}-${year}`)
                    ) : (
                        filter("empty", false),
                        setSelected("Empty")
                    ))}
            >
                <span>{month}</span>
            </div>
        ));
    }

    return(
        <div className="date-filter">
            <div
                className={classList({
                    'year-element': true,
                    'selected': (selected !== false && selected === "Last Week")
                })}
                onClick={() => (
                    (selected !== "Last Week") ? (
                        filter("W", "last"),
                        setSelected("Last Week")
                    ) : (
                        filter("empty", false),
                        setSelected("Empty")
                    ))}
            >
                <span>Last Week</span>
            </div>
            <div
                className={classList({
                    'year-element': true,
                    'selected': (selected !== false && selected === "Last Month")
                })}
                onClick={() => (
                    (selected !== "Last Month") ? (
                        filter("M", "last"),
                        setSelected("Last Month")
                    ) : (
                        filter("empty", false),
                        setSelected("Empty")
                    ))}
            >
                <span>Last Month</span>
            </div>
            <div
                className={classList({
                    'year-element': true,
                    'selected': (selected !== false && selected === "Last Year")
                })}
                onClick={() => (
                    (selected !== "Last Year") ? (
                        filter("Y", "last"),
                        setSelected("Last Year")
                    ) : (
                        filter("empty", false),
                        setSelected("Empty")
                    ))}
            >
                <span>Last Year</span>
            </div>
            {
                dates.map((yearObj, index) => (
                    <div className="date-filter-year" key={index}>
                        <div
                            className={classList({
                                'year-element': true,
                                'selected': (selected !== false && selected === yearObj.year)
                            })}
                            onClick={() => (
                                (selected !== yearObj.year) ? (
                                    filter(yearObj.year, false),
                                    setSelected(yearObj.year)
                                ) : (
                                    filter("empty", false),
                                    setSelected("Empty")
                                ))}
                        >
                            <span>{yearObj.year}</span>
                        </div>
                        {(true) ? (renderYearMonths(yearObj.year, yearObj.months)) : false}
                    </div>
                ))
            }
        </div>
    )
}

export default DateFilter;
