import React from 'react';
import {classList} from '../Helpers/Functions';
import { withTranslation } from 'react-i18next';

function PreferencesPicker({ t, pickerStatus, setPickerStatus }) {
    const pickNames = [1, 2]

    return (
        <div className=''>
            <div className="general-picker">
                {
                    pickNames.map(element => (
                        <div className={classList({
                            "picker": true,
                            "px-1": true,
                            "picker-selected": (element === pickerStatus)

                        })}
                        onClick={() => (
                            setPickerStatus(element)
                        )}>{t(`climate.${element}`)}</div>
                    ))
                }
                <div>

                </div>
            </div>
        </div>
    )
}
export default withTranslation()(PreferencesPicker);
