import React, { useState } from 'react';
import i18n from 'i18next';
import ReactDatePicker from 'react-datepicker';

function DatePicker({ onChange, value }) {
    const [pickerStatus, setPickerStatus] = useState(false);
    return(
        <div className='date-picker'>
            <ReactDatePicker
                open={pickerStatus}
                onClickOutside={() => setPickerStatus(!pickerStatus)}
                onInputClick={() => setPickerStatus(!pickerStatus)}
                name='endTime'
                autoComplete='off'
                locale={i18n.language}
                selected={value}
                onChange={onChange}
                className='form-control'
                dateFormat='dd/MM/yyyy'
                maxDate={new Date()}
                shouldCloseOnSelect={true}
            />
        </div>
    )
}

export default DatePicker;
