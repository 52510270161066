import React, { useEffect, useState, useContext } from 'react';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import TagsFilter from '../Components/TagsFilter';
import Notification from '../Components/Notification';
import DateFilter from '../Components/DateFilter';
import SnoozePopup from '../Components/SnoozePopup';
import Loading from '../Components/Loading';
import { withTranslation } from 'react-i18next';
import { GET_ALERTS, ALERT_TRACKER, SNOOZE_ALERT } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';
import { useLocation } from 'react-router-dom'
import UtilsContext from '../Context/utilsContext';

window.zonesInterval = null;
function Notifications({ i18n, t }) {
    const { customers } = useContext(UtilsContext);
    const [ currentUserBuildings, setCurrentUserBuildings ] = useState([]);
    const [ notifications, setNotifications ] = useState({
        notifications_current: [],
        notifications_filtered: [],
        notifications_byType: [],
        isFiltered: false,
        uniqueCategories: [],
        uniqueBuildings: []
    });
    const { callAPI, apiResult } = useAPI();
    const [ isCheckAll, setIsCheckAll ] = useState(false);
    const [ isCheck, setIsCheck ] = useState([]);
    const [ list, setList ] = useState([]);
    const [ currentDates, setCurrentDates ] = useState([]);
    const [ snoozeSelected, setSnoozeSelected ] = useState({"term": "days", "amount": 0, "alerttypes": {}});
    const [ selectValue, setSelectValue ] = useState({ amount: 0, term: "days"});
    const [ selectedDate, setSelectedDate ] = useState();
    const { data, type } = apiResult;
    const today = moment(moment(new Date().toUTCString()).format('YYYY-M-D'))
    const start_date_fixed = moment('2022-10-1')
    const [visibility, setVisibility] = useState(false);
    const [selectedOptionCategory, setSelectedOptionCategory] = useState([]);
    const [ currentBuildingsChecked, setCurrentBuildingsChecked ] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [alertsCount, setAlertsCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [allCategories] = useState([{value: 0, label: t(`tags.${0}`)}, {value: 1, label: t(`tags.${1}`)}, {value: 2, label: t(`tags.${2}`)}, {value: 3, label: t(`tags.${3}`)}]);
    const [notificationsFilter, setNotificationsFilter] = useState({'lang': i18n.language, 'archived': false, 'category': [], 'zone': [], 'date_after': '', 'date_before': ''});
    const location = useLocation()

    const popupCloseHandler = (e) => {
        setVisibility(e);
    };

    useEffect(() => {
        if (customers.hasOwnProperty('buildings')) {
            setCurrentUserBuildings(customers.buildings)
        }
    }, [customers])

    function getPaginatedAlerts(page_num = currentPage, page_range = rowsPerPage) {
        setIsLoading(true);
        callAPI(GET_ALERTS(notificationsFilter, page_num, page_range))
                .finally(() => {
                    setIsLoading(false);
                });
    }

    useEffect(() => {
        setCurrentPage(0)
        getPaginatedAlerts(0, rowsPerPage)
    }, [location.state, notificationsFilter.archived])

    useEffect(() => {
        if (type === "GET_ALERTS") {
            if (data && !notificationsFilter.archived) {
                let unviewed_notifications_filtered = data.results.filter((notification) => (!notification.viewed) ? notification.id : false)
                let unviewed_notifications = unviewed_notifications_filtered.map((notification) => notification.id)
                if (unviewed_notifications && unviewed_notifications.length > 0) {
                    archiveNotification(unviewed_notifications, false, "seen")
                }
            }
            if (location.state && location.state.toFilter && JSON.stringify(location.state.idList) !== JSON.stringify(notificationsFilter.zone)) {
                setNotificationsFilter(prevState => ({
                    ...prevState,
                    zone: location.state.idList
                  }));
                setCurrentBuildingsChecked(location.state.idList)
            } else {
                createPropertySructure(data.results);
            }
            setAlertsCount(data.count)
        }
    }, [data])

    function archiveNotification(id, archived, name) {
        if (id.length > 0) {
            callAPI(ALERT_TRACKER, { "alerts": id, [name]: !archived })
            setIsCheck([]);
            setIsCheckAll(false);
        } else if (id.length === undefined) {
            callAPI(ALERT_TRACKER, { "alerts": [id], [name]: !archived })
        }
        getPaginatedAlerts(currentPage, rowsPerPage)
    }

    function createPropertySructure(notificationData, isTime) {
        let notifications_byType = []
            let isIn
            notificationData.forEach(notification => {
                if (notifications_byType.length === 0) {
                    notifications_byType.push({"building": notification.building_name, "type": notification.type, "notifications": [notification]})
                } else {
                    notifications_byType.forEach(notification_byType => {
                        if (notification_byType.building === notification.building_name && notification_byType.type === notification.type) {
                            notification_byType.notifications.push(notification)
                            isIn = true
                        }
                    })
                    if (!isIn) {
                        notifications_byType.push({"building": notification.building_name, "type": notification.type, "notifications": [notification]})
                    }
                    isIn = false
                }
            })

            if (isTime) {
                setNotifications({ ...notifications, notifications_current: notificationData,
                    uniqueCategories: getUnique(notificationData, 'category'),
                    uniqueBuildings: getUnique(notificationData, 'building_name'),
                    notifications_byType: notifications_byType });
            } else {
                if (data !== null) {
                    setNotifications({ ...notifications, notifications_current: notificationData,
                        uniqueCategories: getUnique(data.results, 'category'),
                        uniqueBuildings: getUnique(data.results, 'building_name'),
                        notifications_byType: notifications_byType });
                }
            }
    }

    useEffect(() => {
        setSnoozeSelected({...snoozeSelected, term: selectValue.term, amount: parseInt(selectValue.amount)})
    }, [selectValue])

    useEffect(() => {
        setSelectedDate()
    }, [selectedOptionCategory])

    function snoozeNotification(id, type, send=false) {
        setVisibility(true)
        if (type === "multi") {
            let alerttypes = {}
            data.results.forEach(notification => {
                if (id.includes(notification.id)) {
                    if (notification.type in alerttypes) {
                        alerttypes[notification.type].push(notification.id)
                    } else {
                        alerttypes[notification.type] = [notification.id]
                    }
                }
            })
            setSnoozeSelected({...snoozeSelected, alerttypes: alerttypes})
        } else {
            setSnoozeSelected({...snoozeSelected, alerttypes: { [type]: (id.length !== undefined) ? (id) : [id]}})
        }
        if (send) {
            callAPI(SNOOZE_ALERT, snoozeSelected)
            setIsCheckAll(false);
            setVisibility(false);
            setIsCheck([]);
            getPaginatedAlerts(currentPage, rowsPerPage)
        }
    }

    function filterNotifications() {
        let notificationsFiltered = [];
        for(let i = 0; i < notifications.notifications_byType.length; i++) {
            let currentNotification = notifications.notifications_byType[i];

            notificationsFiltered.push(currentNotification);
        }
        return notificationsFiltered;
    }

    useEffect(() => {
        getPaginatedAlerts(currentPage, rowsPerPage)
    }, [notificationsFilter]);

    function updateFilters(filterValue, filterType) {
        if (filterType !== undefined && filterType.hasOwnProperty('action')) {
            setNotificationsFilter(prevState => ({
                ...prevState,
                category: filterValue.map(item => item.value)
              }));
        }
    }

    useEffect(() => {
        if (JSON.stringify(currentBuildingsChecked) !== JSON.stringify(notificationsFilter.zone)) {
            setNotificationsFilter(prevState => ({
                ...prevState,
                zone: currentBuildingsChecked
              }));
        }
    }, [currentBuildingsChecked])

    function getUnique(notifications, name) {
        if (name === "building_name") {
            let buildings = []
            let temp = []
            notifications.forEach(notification => {
                if (buildings.length === 0) {
                    buildings.push({building: notification.building_name, building_id: notification.building, zones: [{name: notification.zone_name, id: notification.zone}]})
                    temp.push(notification.building_name)
                } else {
                    buildings.forEach(element => {
                        if (element.building !== notification.building_name && !(temp.includes(notification.building_name))) {
                            buildings.push({building: notification.building_name, zones: [{name: notification.zone_name, id: notification.zone}]})
                            temp.push(notification.building_name)
                        } else if (element.building === notification.building_name && !(element.zones.includes(notification.zone_name))) {
                            element.zones.push({name: notification.zone_name, id: notification.zone})
                        }
                    });
                }
            })
            return buildings
        } else {
            return [...new Set(notifications.map(item => item[name]))];
        }
    }

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        let arr1d = [].concat.apply([], list.map(li => li.notifications.filter(id => (id.active) ? id.id : false).map(id => id.id)));
        setIsCheck(arr1d)
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
        if (isCheck.length === notifications.notifications_current.length) {
            setIsCheckAll(true);
        } else {
            setIsCheckAll(false);
        }
    };

    const handleChangePage = (e, p) => {
        setCurrentPage(p)
        getPaginatedAlerts(p, rowsPerPage)
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10))
        setCurrentPage(0)
        getPaginatedAlerts(0, e.target.value)
    };

    const handleDateUpdate = (year, month) => {
        let start_date = ''
        let end_date = ''
        if (year === "empty") {
            start_date = ''
            end_date = ''
        } else if (month === "last") {
            if (year === "W") {
                start_date = moment().subtract(1, 'weeks').startOf('week');
                end_date = moment().subtract(1, 'weeks').endOf('week');
            } else if (year === "M") {
                start_date = moment().subtract(1, 'months').startOf('month');
                end_date = moment().subtract(1, 'months').endOf('month');
            } else if (year === "Y") {
                start_date = moment().subtract(1, 'years').startOf('year');
                end_date = moment().subtract(1, 'years').endOf('year');
            }
        } else if (month === false) {
            start_date = moment(year, 'YYYY').startOf('year');
            end_date = moment(year, 'YYYY').endOf('year');
        } else {
            start_date = moment(`${year}-${month}`, 'YYYY-MMMM');
            end_date = moment(`${year}-${month}`, 'YYYY-MMMM').endOf('month');
        }

        setNotificationsFilter(prevState => ({
            ...prevState,
            date_after: start_date.format('YYYY-MM-DD HH:mm'),
            date_before: end_date.format('YYYY-MM-DD HH:mm')
          }));
    };

    useEffect(() => {
        setList(notifications.notifications_byType);
    }, [list, notifications.notifications_byType]);

    useEffect(() => {
        var timeValues = [];
        let dates = []
        let temp = []

        while (today > start_date_fixed || start_date_fixed.format('M') === today.format('M')) {
            timeValues.push(start_date_fixed.toISOString());
            start_date_fixed.add(1, "month");
        }

        timeValues.forEach(date => {
            if (dates.length === 0) {
                dates.push({year: moment(date).format('YYYY'), months: [moment(date).format('MMMM')]})
                temp.push(moment(date).format('YYYY'))
            } else {
                dates.forEach(element => {
                    if (element.year !== moment(date).format('YYYY') && !(temp.includes(moment(date).format('YYYY')))) {
                        dates.push({year: moment(date).format('YYYY'), months: [moment(date).format('MMMM')]})
                        temp.push(moment(date).format('YYYY'))
                    } else if (element.year === moment(date).format('YYYY') && !(element.months.includes(moment(date).format('MMMM')))) {
                        element.months.push(moment(date).format('MMMM'))
                    }
                });
            }
        })
        setCurrentDates(dates)
    }, [notifications.notifications_current]);

    return (
        <div className="notifications">
            <div className="App">
                <SnoozePopup
                    onClose={popupCloseHandler}
                    show={visibility}
                >
                    <h3>Snooze</h3>
                    <div className="popup-body">
                        <input min={0} onChange={(e) => setSelectValue({ ...selectValue, amount: e.target.value})} type="number" className="popup-input" placeholder="#"></input>
                        <select onChange={(e) => setSelectValue({ ...selectValue, term: e.target.value})} className="popup-select">
                            <option value="days">Days</option>
                            <option value="weeks">Weeks</option>
                            <option value="months">Months</option>
                            <option value="years">Years</option>
                        </select>
                    </div>
                    <button className="popup-ok-button" onClick={() => snoozeNotification('', '', true)}>Ok</button>
                </SnoozePopup>
            </div>
            <TagsFilter
                notificationsFilter={notificationsFilter}
                setNotificationsFilter={setNotificationsFilter}
                updateFilters={updateFilters}
                categories={allCategories}
                buildings={currentUserBuildings}
                currentBuildingsChecked={currentBuildingsChecked}
                setCurrentBuildingsChecked={setCurrentBuildingsChecked}
            />
            {
                isLoading ? (
                    <Loading></Loading>
                ) : (
                    <div className="notifications-list">
                    {
                        (filterNotifications().length > 0) ? (
                            <div className="notifications-common">
                                <input className="notification-checkmark"
                                    checked={isCheckAll}
                                    onChange={handleSelectAll}
                                    id="selectAll"
                                    type="checkbox" />
                                <div className="archive-icon" onClick={() => archiveNotification(isCheck, notificationsFilter.archived, "archived")}></div>
                                <div className="snooze-icon" onClick={() => {
                                    snoozeNotification(isCheck, 'multi', false)
                                    setVisibility(true)}}></div>
                            </div>
                        ) : false
                    }
                    {
                        (filterNotifications().length > 0) ? (
                            filterNotifications().map(notification => (
                                <Notification
                                    {...notification}
                                    archive={archiveNotification}
                                    snooze={snoozeNotification}
                                    notification={notification}
                                    category={notification.category}
                                    id={notification.id}
                                    building_name={notification.building_name}
                                    zone_name={notification.zone_name}
                                    date={notification.date}
                                    alert={notification.alert}
                                    archived={notification.archived}
                                    checked={isCheck.includes(notification.notifications[0].id)}
                                    handleClick={handleClick}
                                    benefit={notification.benefit}
                                    active={notification.active}
                                    type={notification.type}
                                />
                            ))
                        ) : (
                            <Notification
                                title={null}
                            />
                        )
                    }
                    </div>
                )
            }
            <TablePagination
                showFirstButton
                showLastButton
                component="div"
                count={alertsCount}
                page={currentPage}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div className="notifications-list-blur" />
            <div className="notifications-filter">
                {
                    (notificationsFilter.archived) ? (
                        <DateFilter
                            dates={currentDates}
                            filter={handleDateUpdate}
                            selected={selectedDate}
                            setSelected={setSelectedDate}
                        />
                    ) : false
                }
            </div>
        </div>
    )
}
export default withTranslation()(Notifications);
