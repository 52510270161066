import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

let translateLng = (navigator.language.split('-').length === 2 ) ? navigator.language.split('-')[0] : navigator.language;

i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/translation.json'
        },
        fallbackLng: 'es',
        keySeparator: '.', // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        lng: translateLng,
        react: {
            useSuspense: false,
        }
    });

document.documentElement.setAttribute('lang', translateLng);

export default i18n;
