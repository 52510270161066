import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
function SettingsTimetable({ days, i18n, setZoneSettingsData, t }) {
    function changeStartTime(value, day) {
        setZoneSettingsData((prevState) => {
            return {
				...prevState,
				days: {
					...days,
					[day[0]]: {
						...days[day[0]],
						start: moment(value).format('HH:mm:ss')
					},
				}
			}
        });
    };

    function changeEndTime(value, day) {
        setZoneSettingsData((prevState) => {
			return {
				...prevState,
				days: {
					...days,
					[day[0]]: {
						...prevState.days[day[0]],
						end: moment(value).format('HH:mm:ss')
					},
				}
			}
		});
    };

    function switchDay(day) {
        setZoneSettingsData((prevState) => {
			return {
				...prevState,
				days: {
					...days,
					[day[0]]: {
						...days[day[0]],
						enabled: !prevState.days[day[0]].enabled
					},
				}
			}
		});
	};

    return(
        <div className='card p-3 mb-sm-3 mb-md-0'>
        	<h5 className='chart-title'>{t('climate.select_hours')}</h5>
        	<div className='g-row my-2'>
        		<div className='px-1'>{t('common.day')}</div>
        		<div className='px-1'>{t('common.start')}</div>
        		<div className='px-1'>{t('common.end')}</div>
        		<div className='px-1'>{t('common.activate')}</div>
        	</div>
        	{
            	Object.entries(days).map(day => {
                	return (
                    	<div className='g-row' key={day[0]}>
                    		<div className='px-1 '>
                    			<input type='text' className='form-control text-capitalize'
                    			value={t(`weekdays.${day[0]}`)} disabled={true}/>
                    		</div>
                    		<div className='px-1 '>
                    			<DatePicker
                    				name='startTime'
                    				autoComplete='off'
                    				locale={i18n.language}
                    				selected={
										day[1].start ?
											new moment(`2001-01-01 ${day[1].start}`).toDate()
                    				        : null
                    				    }
                    				onChange={(value) => changeStartTime(value, day)}
                    				showTimeSelect
                    				showTimeSelectOnly
                    				timeIntervals={15}
                    				className='form-control'
                    				dateFormat='HH:mm'
                    				timeFormat='HH:mm'
                    				timeCaption={t('common.start')}
                    				 placeholderText='00:00'
                    			/>
                        	</div>
                        	<div className='px-1 '>
                        		<DatePicker
                        			name='endTime'
                        			autoComplete='off'
                        			locale={i18n.language}
                        			selected={
                        			    day[1].end ? new moment(
                        			        '2001-01-01' +
                        			        ' ' +
                        			        day[1].end).toDate()
                        			        : null
                        			    }
                        			onChange={(value) => changeEndTime(value, day)}
                        			showTimeSelect
                        			showTimeSelectOnly
                        			timeIntervals={15}
                        			className='form-control'
                        			dateFormat='HH:mm'
                        			timeFormat='HH:mm'
                        			timeCaption={t('common.end')}
                        			placeholderText='00:00'
                            	/>
                            </div>
                            <div className='px-1 '>
                            	<label className='switch' title=''>
									<input
										name='switch'
										type='checkbox'
										value='1'
										onChange={() => switchDay(day)}
										checked={day[1].enabled}
									/>
									<span className='slider round'/>
                            	</label>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
};

export default withTranslation()(SettingsTimetable);
