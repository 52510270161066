import React, { useEffect, useState } from 'react';
import { classList } from '../Helpers/Functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { ModuleTypes } from '../Helpers/ModuleTypes';

function BuildingMenuItem({ building, count }) {
    const [menuItemData, setMenuItemData] = useState({ building, buildingId: null, zoneId: null, zone: null, buildingOnCO2: false });
    const history = useHistory();
    let currentPage = window.location.pathname.split('/')[1];

    let buildingHasCO2 = false;
    building.zones.forEach(zone => zone.modules.forEach(module => (module.module_type === ModuleTypes.CO2) ? buildingHasCO2 = true : false));


    function getZoneIdsFromBuildingId() {
        return menuItemData.building.zones.map(zone => zone.id)
    }

    useEffect(() => {
        setMenuItemData({...menuItemData, building: building})
    }, [building])

    function setCurrentLocation() {
        let zoneId = (currentPage === 'dashboard') ? Number(parseInt(window.location.pathname.split('/')[2])) : 0;
        let zone = building.zones.find(zone => zone.id === zoneId);
        if(zone) {
            zone.isOpen = !zone.isOpen;
        }

        if(zone) {
            return setMenuItemData({
                ...menuItemData,
                zone: zone ? zone : null,
                zoneId: zone ? zone.id : null,
                buildingId: zone ? building.id : null,
                building: {
                    ...menuItemData.building,
                    isOpen: true,
                    zones: building.zones
                },
                buildingOnCO2: (currentPage === 'building' && buildingHasCO2)
            });
        }

        if (menuItemData.building.id === menuItemData.buildingId) {
            return setMenuItemData({
                ...menuItemData,
                zone: zone ? zone : null,
                zoneId: zone ? zone.id : null,
                buildingId: zone ? zone.building.id : null,
                building: {
                    ...menuItemData.building,
                    isOpen: false,
                    isOpen_force: false
                },
                buildingOnCO2: (currentPage === 'building' && buildingHasCO2)
            });
        } else {
            return setMenuItemData({
                ...menuItemData,
                building: {
                    ...menuItemData.building,
                    isOpen: false,
                    isOpen_force: false
                },
                buildingOnCO2: (currentPage === 'building' && buildingHasCO2)
            });
        }
    }

    function toggleBuildingOpen() {
        setMenuItemData({
            ...menuItemData,
            building: {
                ...menuItemData.building,
                isOpen: !menuItemData.building.isOpen
            },
            buildingOnCO2: (currentPage === 'building' && buildingHasCO2)
        });
    }

    function selectBuilding() {
        if(buildingHasCO2) {
            setMenuItemData({ ...menuItemData, zoneId: null, zone: null, buildingOnCO2: true });
            history.push(`/building/${menuItemData.building.id}`);

        } else {
            setMenuItemData({ ...menuItemData, buildingOnCO2: false });
            toggleBuildingOpen();
        }
    }

    useEffect(() => {
        setCurrentLocation();
    }, [window.location.pathname]);

    if (menuItemData.building) {
        let requestedId = Number(parseInt(window.location.pathname.split('/')[2]));
        return (
            <div className={classList({
                'sidebar-item': true,
                'sidebar-item--open': menuItemData.building.isOpen,
                'sidebar-item--current': menuItemData.buildingId !== null,
                'sidebar-item--building-current': (menuItemData.zoneId == null && menuItemData.building.id === requestedId && menuItemData.buildingOnCO2)
            })}>
                <button className="btn-link">
                    <span className='sidebar-item__icon'>
                        <div className={classList({
                            'ico': true,
                            'ico--building': menuItemData.buildingId === null && count === 0,
                            'ico--building--active': menuItemData.buildingId !== null && count === 0,
                            'alerts': count !== 0
                        })}
                        >
                            {
                                (count !== 0) ? (
                                    <Link
                                    to={{
                                        pathname: "/notifications",
                                        state: {idList: getZoneIdsFromBuildingId(), toFilter: true}
                                    }}>{count}!</Link>
                                ) : false
                            }</div>
                    </span>
                    <span onClick={selectBuilding} className='sidebar-item__text'>
                        {menuItemData.building.name}
                    </span>
                </button>
                <div
                    onClick={toggleBuildingOpen}
                    className={classList({
                        'sidebar-item__arrow': true,
                        'sidebar-item__arrow--down': menuItemData.building.isOpen || menuItemData.building.isOpen_force,
                        'sidebar-item__arrow--current': menuItemData.zoneId !== null
                    })}>
                    <FontAwesomeIcon
                        icon={faAngleRight}
                    />
                </div>
                <div className={classList({
                    'sidebar-item__zones': true,
                    'sidebar-item__zones--open': menuItemData.building.isOpen ||  menuItemData.building.isOpen_force
                })}>
                    {
                        menuItemData.building.zones.map(function (zone) {
                            if (zone.modules.length) {
                                return (
                                    <div key={zone.id}>
                                    <span className={classList({
                                        'sidebar-item__text': true,
                                        'sidebar-item__text--open': menuItemData.building.isOpen,
                                        'sidebar-item__text--current': menuItemData.zoneId === zone.id
                                    })}
                                    >
                                        <Link to={`/dashboard/${zone.id}`} className='is-current current'>{zone.name}</Link>
                                    </span>
                                    </div>
                                )
                            } else {
                                return false;
                            }
                        })
                    }
                </div>
            </div>
        )
    } else {
        return false;
    }
};

export default BuildingMenuItem;
