import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';
import { handleWebserviceError } from '../Helpers/Functions';
import { GET_CHART_LIGHTING } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';
import { uuidv4 } from '../Helpers/Functions';

window.lightingInterval = null;
function ChartLighting({ date, zone_id, t }) {
    const [chartData, setChartData] = useState({ chartUUID: '', highcharts: undefined, zone_id });
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;
    let maxY = 0;

    useEffect(() => {
        if(chartData.zone_id !== undefined) {
            window.clearInterval(window.lightingInterval);
            callAPI(GET_CHART_LIGHTING(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            window.lightingInterval = window.setInterval(() => {
                callAPI(GET_CHART_LIGHTING(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            }, 300000);
        } else {
            setChartData({ highcharts: undefined, zone_id });
        }
    }, [chartData.zone_id, date]);

    useEffect(() => {
        if(chartData.zone_id !== zone_id) {
            setChartData({ highcharts: undefined, zone_id });
        }
    }, [zone_id]);

    useEffect(() => {
        if(data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined && type === 'GET_CHART_LIGHTING') {
            setChartData({ ...chartData, chartUUID: uuidv4(), highcharts: data.lighting });
        }
    }, [data]);

    if(chartData.highcharts !== undefined && chartData.highcharts.luxes !== undefined) {
        function getMaxY() {
            let luxes = Array.from(chartData.highcharts.luxes);
            luxes.push(chartData.highcharts.threshold);

            maxY = Math.max(...luxes);
        }

        getMaxY();

        var lightingChart = {
            chart: {
                height: 355,
                type: 'column'
            },
            legend: false,
            title: false,
            xAxis: {
                categories: chartData.highcharts.date,
                crosshair: true
            },
            yAxis: {
                min: 0,
                max: maxY,
                plotLines: [{
                    color: '#EDEDED',
                    value: chartData.highcharts.threshold,
                    width: 4
                }],
                title: {
                    text: t('lighting.brightness')
                }
            },
            plotOptions: {
                column: {
                    minPointLength: 5
                },
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: true
                },
                series: {
                    pointStart: 0
                }
            },
            series: [{
                name: 'Luxes',
                data: Array.from(chartData.highcharts.luxes),
                color: '#00FFCC',
                threshold: null
            }]
        };
    }

    function colorPoints() {
        // Asign custom gradient to each point depending on it's level.
        this.series[0].points.forEach((point, i) => {
            let gradientHeight = (chartData.highcharts.level[i] >= 3) ? 100 : (chartData.highcharts.level[i] <= 1) ? 33 : 66;
            let ajustedGradientHeight = gradientHeight / point.shapeArgs.height;
            let topColor = (chartData.highcharts.level[i] < 0) ? 'rgba(179, 179, 179, 1)' : (chartData.highcharts.level[i] > 0) ? 'rgba(0, 255, 204, 1)' : 'rgba(83, 93, 91, 1)';
            let bottomColor = (chartData.highcharts.level[i] < 0) ? 'rgba(179, 179, 179, 0)' : (chartData.highcharts.level[i] > 0) ? 'rgba(0, 255, 204, 0)' : 'rgba(83, 93, 91, 0)';

            point.update({
                color: {
                    linearGradient: {
                      x1: 0,
                      x2: 0,
                      y1: 0,
                      y2: 1
                    },
                    stops: [
                      [0, topColor],
                      [ajustedGradientHeight, bottomColor]
                    ]
                }
            });
        });

        // Assign custom border color depending on it's level.
        const chartPoints = document.querySelectorAll('#lighting-chart .highcharts-point');
        for(let i = 0; i < chartPoints.length; i++) {
            let currentPoint = chartPoints[i];
            let currentPointFill = currentPoint.getAttribute('fill');
            currentPoint.setAttribute('stroke', currentPointFill);
        }
    }

    function onValues() {
        let hasOn = false;
        if(chartData.highcharts !== undefined && chartData.highcharts.luxes !== undefined) {
            chartData.highcharts.level.forEach(level => {
                if(level >= 1) {
                    hasOn = true;
                }
            });
        }
        return hasOn;
    }

    if(chartData.highcharts !== undefined && lightingChart !== undefined && chartData.highcharts.level.length > 0) {

        Highcharts.setOptions({
            lang: {
              thousandsSep: '.'
            }
        });
        lightingChart.xAxis.categories = chartData.highcharts.date;

        return (
            <div>
                <div className='chart-container' id="lighting-chart">
                    <h6 className='chart-title pt-3 ml-3'>{t('common.lighting')}</h6>
                    <HighchartsReact
                        key={chartData.chartUUID}
                        highcharts={Highcharts}
                        options={lightingChart}
                        callback={colorPoints}
                    />
                    <div className="lighting-leyend">
                        <div className="lighting-leyend-item lighting-leyend-threshold">
                            <div className="lighting-leyend-line"></div>
                            <p>{t('lighting.threshold')}</p>
                        </div>
                        <div className="lighting-leyend-group">
                            {
                                (chartData.highcharts !== undefined && onValues()) ? (
                                    <div className="lighting-leyend-item">
                                        <div className="leyend-point" style={{ background: 'rgba(0, 255, 204, 1)' }}></div>
                                        <p>On</p>
                                    </div>
                                ) : false
                            }
                            {
                                (chartData.highcharts !== undefined && chartData.highcharts.level.includes(0)) ? (
                                    <div className="lighting-leyend-item">
                                        <div className="leyend-point" style={{ background: 'rgba(121, 129, 133, 1)' }}></div>
                                        <p>Off</p>
                                    </div>
                                ) : false
                            }
                            {
                                (chartData.highcharts !== undefined && chartData.highcharts.level.includes(-1)) ? (
                                    <div className="lighting-leyend-item">
                                        <div className="leyend-point" style={{ background: 'rgba(179, 179, 179, 1)' }}></div>
                                        <p>{t('common.unknown')}</p>
                                    </div>
                                ) : false
                            }
                        </div>

                    </div>
                </div>
            </div>
        )
    } else {
        return false;
    }
}

export default withTranslation()(ChartLighting);
