import React from 'react';
import { withTranslation } from 'react-i18next';
import Building from '../Img/building.jpg';

function TemperatureController({ co2_threshold, enabled_co2, setZoneSettingsData, t, target }) {
    function changeCo2Value(e) {
        if (!isNaN(e.target.value)) {
            setZoneSettingsData((prevState) => {
                return {
                    ...prevState,
                    co2_threshold: e.target.value
                }
            })
        }
    }
    function changeTemperature(e) {
        if (!isNaN(e.target.value)) {
            setZoneSettingsData((prevState) => {
                return {
                    ...prevState,
                    settings: {
                        ...prevState.settings,
                        target: e.target.value
                    },
                    target: e.target.value,
                    enableSaveSettings: true
                };
            });
        } else {
            return false;
        }
    };

    return(
        <div className='card p-3 mb-sm-3 mb-md-0'>
            <h5 className='chart-title'>{t('climate.select_desired_values')}</h5>
            <div className='g-row'>
                <label htmlFor='target' className="m-1">{t('common.temperature')} ºC</label>
                {
                    enabled_co2 && (<label htmlFor='co2' className="m-1">Max CO<sub>2</sub> ppm</label>)
                }
            </div>
            <div className='g-row'>
                <input
                    type='number'
                    name='target'
                    className='number-selector m-1'
                    id='target'
                    min='17'
                    max='30'
                    placeholder='21.0'
                    value={target ? target : '' }
                    onChange={changeTemperature}
                />
                {
                    enabled_co2 && (
                        <input
                            type='number'
                            name='co2'
                            className='number-selector m-1'
                            id='co2'
                            min='700'
                            max='1500'
                            placeholder='1000'
                            value={co2_threshold ? co2_threshold : '' }
                            onChange={changeCo2Value}
                        />
                    )
                }

            </div>
            <img
                src={Building}
                className='mt-3'
                style={{height: 'auto', width: '100%'}}
                alt='Building'
            />
        </div>
    )
};

export default withTranslation()(TemperatureController);
